// import {
//   Dialog,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   IconButton,
//   RootRef
// } from '@material-ui/core'
// import HelpIcon from '@material-ui/icons/Help'
// import { makeStyles } from '@material-ui/styles'
// import { useCollection } from '@sb-konzept/firebase-hooks'
// import Box from 'components/Box'
// import { useLanguage } from 'components/LocaleProvider'
// import { useRole } from 'hooks/useIsRole'
// import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
// import ReactDOM from 'react-dom'
// import { FormattedMessage } from 'react-intl'
// import YouTubePlayer from 'react-player/lib/players/YouTube'

// const useStyles = makeStyles(() => ({
//   playerWrapper: {
//     position: 'relative',
//     paddingTop: '56.25%',
//     overflow: 'hidden',
//     marginBottom: 24
//   },
//   player: {
//     position: 'absolute',
//     top: 0,
//     left: 0
//   }
// }))

// function TutorialButton(props) {
//   const { position, onClick } = props

//   return ReactDOM.createPortal(
//     <Box
//       left={position.x - 24}
//       position="absolute"
//       top={position.y - 24}
//       zIndex={1200}
//     >
//       <IconButton onClick={onClick}>
//         <HelpIcon />
//       </IconButton>
//     </Box>,
//     document.getElementById('tutorial-root')
//   )
// }

// function TutorialModal(props) {
//   const { name, tutorial } = props
//   const classes = useStyles()

//   if (!tutorial) {
//     return (
//       <>
//         <DialogTitle>
//           <FormattedMessage
//             defaultMessage="Tutorial not found"
//             id="Tutorial.modal.notFoundTitle"
//           />
//         </DialogTitle>

//         <DialogContent>
//           <DialogContentText>
//             <FormattedMessage
//               defaultMessage="We couln't find the tutorial {name} for your role and language."
//               id="Tutorial.modal.notFound"
//               values={{ name }}
//             />
//           </DialogContentText>
//         </DialogContent>
//       </>
//     )
//   }

//   return (
//     <>
//       <DialogTitle>{tutorial.title}</DialogTitle>
//       <div className={classes.playerWrapper}>
//         <YouTubePlayer
//           className={classes.player}
//           height="100%"
//           url={tutorial.youtubeLink}
//           width="100%"
//         />
//       </div>
//       <DialogContent>
//         <DialogContentText>{tutorial.description}</DialogContentText>
//       </DialogContent>
//     </>
//   )
// }

// function Tutorial(props) {
//   const { name, children, orientation = 'left' } = props
//   const ref = useRef()
//   const [role, loadingRole] = useRole()
//   const [language, loadingLanguage] = useLanguage()
//   const [showModal, setShowModal] = useState(false)
//   const [position, setPosition] = useState({ x: 0, y: 0 })

//   const { loading, value: [tutorial] = [] } = useCollection(
//     'collections/tutorials/entities',
//     (query) =>
//       query
//         .where('name', '==', name)
//         .where('role', '==', role || null)
//         .where('language', '==', language)
//   )

//   useLayoutEffect(() => {
//     let cancelled = false
//     setTimeout(() => {
//       if (cancelled) return
//       const rect = ref.current.getBoundingClientRect()
//       setPosition({
//         y: rect.y,
//         x: rect.x + (orientation === 'left' ? 0 : rect.width)
//       })
//     }, 1000)

//     return () => {
//       cancelled = true
//     }
//   }, [orientation])

//   const handleCloseModal = useCallback(() => setShowModal(false), [])
//   const handleOpenModal = useCallback(() => setShowModal(true), [])

//   return (
//     <>
//       <RootRef rootRef={ref}>{children}</RootRef>
//       {!(loading || loadingRole || loadingLanguage || !tutorial) && (
//         <>
//           <TutorialButton position={position} onClick={handleOpenModal} />
//           <Dialog
//             fullWidth
//             maxWidth="md"
//             open={showModal}
//             onClose={handleCloseModal}
//           >
//             <TutorialModal
//               language={language}
//               name={name}
//               role={role}
//               tutorial={tutorial}
//             />
//           </Dialog>
//         </>
//       )}
//     </>
//   )
// }

export default (props) => {
  // if (typeof window === 'undefined') {
  return props.children
  // }

  // return <Tutorial {...props} />
}
