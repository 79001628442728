import * as R from 'ramda'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { useUser } from 'components/UserContext'

const MockedRoleContext = createContext(['actual', () => null])

export function MockedRoleProvider({ children }) {
  const [mockedRole, setMockedRole] = useState('actual')
  return (
    <MockedRoleContext.Provider value={[mockedRole, setMockedRole]}>
      {children}
    </MockedRoleContext.Provider>
  )
}

export function useMockedRole() {
  return useContext(MockedRoleContext)
}

export function useRole() {
  const user = useUser()

  const [mockedRole] = useMockedRole()
  return [mockedRole !== 'actual' ? mockedRole : user?.role, false]
}

export default function useIsRole() {
  const [role, loadingRole] = useRole()

  const isRole = useCallback(
    (roles, defaultValue = false) => {
      if (role === null) return defaultValue
      if (R.is(Array, roles)) {
        return roles.includes(role) || defaultValue
      }
      if (R.is(Object, roles)) {
        return roles[role] || defaultValue
      }
      return roles === role || defaultValue
    },
    [role]
  )
  return [isRole, loadingRole]
}
